import { Ref, ref } from 'vue'

export const bottomBar = ref(true)

export const bottomBarShow = ():void => {
    bottomBar.value = true
}

export const bottomBarHide = ():void => {
    bottomBar.value = false
}

export const bottomBarRef: Ref<HTMLElement | null> = ref(null)